<template>
  <v-container>
    <h1 v-html="$t('translations_title')"></h1>
    <div>
      <v-row class="row search spacer-md">
        <v-col xs="12" md="6" xl="4">
          <search-translation ref="search-translation" @search="search" />
        </v-col>
      </v-row>
      <v-btn color="primary" :loading="loading" @click="search">
        <span v-html="$t('search_translation_search')" />
      </v-btn>
      <v-btn color="secondary" @click="reset">
        <span v-html="$t('search_translation_reset')" />
      </v-btn>
      <v-btn
        color="secondary"
        :to="{
          name: 'translation_create'
        }"
      >
        <span v-html="$t('translations_button_create')" />
      </v-btn>
      <v-data-table-server
        :items="itemlist"
        :headers="fields"
        :fields="fields"
        :loading="loading"
        :items-length="totalElements"
        :multi-sort="true"
        @update:itemsPerPage="routerPushSize"
        @update:page="routerPushPage"
        @update:sort-by="routerPushDTSort"
        :sort-by="toDTSort(query.sort)"
        :page="getDTPageFromRoute(query.page)"
        :items-per-page="query.size"
        :items-per-page-options="$itemsPerPageOptions"
        density="compact"
      >
        <template v-slot:[`item.edit`]="{ index }">
          <template v-if="itemlist[index].id">
            <router-link :to="{ name: 'translations_write', params: { id: itemlist[index].id } }" class="tableaction link">
              <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
            </router-link>
          </template>
        </template>
        <template v-slot:[`item.id`]="{ value }">
          {{ value }}
        </template>
      </v-data-table-server>
      <v-btn class="m-2" color="primary" @click="downloadXlsx">
        <span v-html="$t('translations_download')" />
      </v-btn>
    </div>
  </v-container>
</template>
<script lang="ts">
import { DTSHeaders } from '@/services/BackendService'
import { apiURL } from '../../main'
import schema from './translationSchema.json'
import { Term } from '../../services/term'
import { fileCreator, showError } from '@/services/axios'
import searchTranslation from '@/components/searchCards/searchTranslation.vue'
import { defineComponent } from 'vue'
import { useSearchTranslationStore } from '@/store/SearchTranslationStore'
import type { components } from '@/lib/api/v1.d.ts'
type Language = components['schemas']['Language']

export default defineComponent({
  name: 'Translations',
  data() {
    return {
      itemlist: [] as Language[],
      loading: false,
      apiURL: apiURL + '/translations/query',
      fields: [
        {
          title: this.$t('translations_table_header_edit'),
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // Id
        {
          title: this.$t('translations_table_header_id'),
          key: 'id',
          sortable: false,
          align: 'left'
        },
        // Message key
        {
          title: this.$t('translations_table_header_message_key'),
          key: 'messageKey',
          sortable: true
        },
        //Deutscher text
        {
          title: this.$t('translations_table_header_text_de'),
          key: 'textDe',
          sortable: true
        },
        // Französischer text
        {
          title: this.$t('translations_table_header_text_fr'),
          key: 'textFr',
          sortable: true
        },
        // Italienischer Text
        {
          title: this.$t('translations_table_header_text_it'),
          key: 'textIt',
          sortable: true
        }
      ] as DTSHeaders,
      selected: undefined,
      schema: schema,
      dialog: false,
      totalElements: 0
    }
  },
  computed: {
    term(): any {
      return [...Term.buildTermItems(useSearchTranslationStore(), useSearchTranslationStore().items)]
    },
    params() {
      return {
        textDe: useSearchTranslationStore().textDe,
        textFr: useSearchTranslationStore().textFr,
        textIt: useSearchTranslationStore().textIt,
        messageKey: useSearchTranslationStore().messageKey
      }
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        //...this.params,
        //...this.getJavaPageOptions(backendService.getJavaSort(defaultSortOptions.sortBy))
        ...this.getJavaPageOptions({ size: 50, sort: 'id,desc', page: 0 })
      }
    }
  },
  components: {
    searchTranslation
  },
  methods: {
    show(data: any) {
      return data.value
    },
    search() {
      this.$router
        .push({
          //name: 'translations_read',
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          console.log(' name: translations_read route duplicated', e)
          //this.load()
        })
    },
    async load() {
      try {
        this.loading = true

        const response = await this.axios.get(apiURL + '/translations/query', {
          params: this.query
        })
        const items = response.data.content
        this.itemlist = Array.isArray(items) ? items : ([] as any)

        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (error) {
        showError(error)
      } finally {
        this.loading = false
      }
    },
    editTranslation(record: any) {
      const id = record.id
      this.$router.push({ name: 'translations_write', params: { id: id } }).catch(() => {
        /* duplicated route */
      })
    },
    async submit() {
      try {
        await this.axios.post(apiURL + '/translations', { ...this.model }, { headers: { 'Content-Type': 'application/json' } })
      } catch (e) {
        showError(e)
      }
    },
    async downloadXlsx() {
      try {
        const response = await this.axios.get(apiURL + '/translations', {
          params: {
            term: ''
          },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(response)
      } catch (e) {
        let responseObj = e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      }
    },
    reset() {
      this.$refs['search-translation'].reset()
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue, oldValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.query.size) this.load()
  }
})
</script>
